
@font-face {
    font-family: 'Harmonia Sans';
    src: url('./Harmonia-Sans-Font/Harmonia Sans W01 Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}



body {
    --header-mobile: 56px;
    margin: 0;
    padding: 0;
    background: grey;
    overflow-x: hidden;
    font-family: 'Harmonia Sans', sans-serif;


    &.dark-theme {
        main {
            background: black !important;
            color: white !important;

            .stepper-form-wrapper-main .fields .buttons button:not(:disabled) {
                color: #efefef;
                border-color: #efefef;
            }

            input.form-control {
                background: transparent;
                border-color: darkgrey;
                color: #fff;
            }

            .react-datepicker {
                background: transparent !important;
                color: #bebebe !important;

                .react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {
                    color: #bebebe;
                }

                .react-datepicker__header {
                    background-color: transparent !important;
                }

                .react-datepicker__current-month {
                    color: #bebebe;
                }
            }
            .stepper-wrapper .stepper-item {
                color: #656565;
            }

            .look_builder_wrapper .col.person-col {
                background: #232323;
                .person-wrapper{
                    background: inherit;
                }
            }
            .sidebar-area{
                width: 420px;

                .sidebar-tabs-list{
                    display: flex;
                    align-items: center;
                    justify-content: flex-start;
                    overflow-x: auto;
                    overflow-y: hidden;
                    white-space: nowrap;
                    width: 100%;
                    background-color: #000000;
                    padding: 0;

                    scrollbar-width: none;
                    -ms-overflow-style: none;

                    &::-webkit-scrollbar {
                        display: none;
                    }

                    li{
                        width: 200px;
                        background-color: #000000;
                        border: none;
                        color:#ffffff;
                        margin-right: 10px;
                        border-radius: 0;
                        font-size: 13px;
                        padding: 10px 20px;
                        margin-bottom: 1px;
                        outline: none;
                        &.react-tabs__tab--selected{
                            border: none;
                            border-bottom: 2px solid #ffffff;
                        }
                        &.react-tabs__tab:focus:after{
                            content: none !important;
                        }
                    }
                }

                .tab{

                    .tab-heading{

                    }

                    .tab-column{
                        overflow-y: scroll;
                        height: calc(100vh - 300px);
                    }

                }

                .jacket-card{
                    padding: 18px 18px;
                    border-radius: 6px;
                    border: 1px solid #cecece;
                    margin-bottom: 20px;
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    &.selected{
                        border-color: #7e7e7e;
                    }
                    &:hover{
                        border-color: #7e7e7e;
                    }
                    .title{
                        font-weight: 500;
                        color: black;
                    }
                    .option{
                        color: grey;
                        font-size: 13px;
                    }
                }



                .fabric-tab{
                    //padding: 0;
                    .fabrics-selector{
                        .fabric-card{
                            padding: 6px;
                            margin-bottom: 30px;
                            border: 1px solid transparent;
                            border-radius: 4px;
                            &.selected{
                                border-color: #7e7e7e;
                            }
                            .fabric-image{
                                height: 140px;
                                width: 100%;
                                img{
                                    height: 100%;
                                    width: 100%;
                                    border-radius: 6px;
                                    object-fit: cover;
                                }
                            }
                            .details{
                                padding: 0 10px;
                            }
                        }
                    }
                }


                .sidebar-footer{
                    height: 103px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    border-top: 1px solid #bbbbbb;

                    button{
                        min-width: 48%;
                        margin: 2px;
                    }
                    span{
                        font-size: 12px;
                    }
                }
            }
        }
    }

    .app {
        header {
            .navbar {
                background: black !important;

                .fa {
                    color: #fff !important;
                }
            }

            .side-wrapper {
                min-height: 100vh;
                background-color: rgba(0, 0, 0, 0.2);
                position: fixed;
                transition: opacity 100ms ease-in-out;
                z-index: 11;
                left: -100%;
                opacity: 0;
                width: 100vw;

                .side-nav {
                    background-color: white;
                    min-height: 100vh;
                    width: 260px;
                    left: -100%;
                    transition: all 200ms ease-in-out;
                }

                &.active {
                    left: 0;
                    opacity: 1;

                    .side-nav {
                        left: 0;
                    }
                }

            }


        }

        main {
            background: white;
        }

    }
}


.look_builder_wrapper {
    background: grey;

    & > .row {
        display: flex;
        height: calc(var(--vh, 1vh) * 100 - var(--header-mobile));

        @media screen and (max-width: 460px) {
            flex-direction: column;
        }
    }

    .col {
        width: 100%;

        &.person-col {
            padding: 0;
            height: calc(100vh - 56px);
            background: #efefef;
            display: flex;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 460px) {
                max-height: calc(100% - 130px);
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        &.sidebar {
            max-width: 180px;
            max-height: 100%;
            //overflow-y: scroll;
            //todo: test this overflow
            padding: 0;

            @media screen and (max-width: 460px) {
                min-height: 130px;
                max-height: 130px;
                display: flex;
                flex-direction: row;
                min-width: 100%;
                max-width: 100vw;
                overflow-x: hidden;
                padding: 0;
                background: black;

                overflow: visible;
            }

            .next-btn-wrapper {
                position: absolute;
                left: -140px;
                bottom: 16px;

                button {
                    height: 38px;
                    width: 120px;
                    background-color: black;
                    border-radius: 40px;
                    color: white;
                    border: 0;
                }

                @media screen and (max-width: 460px) {
                    height: 40px;
                    width: 130px;
                    right: 10px;
                    left: auto;
                    z-index: 111;
                    top: -50px;


                }
            }


            /* Hide scrollbar for Chrome, Safari and Opera */
            &::-webkit-scrollbar {
                display: none;
            }

            * {
                &::-webkit-scrollbar {
                    display: none;
                }
            }

            * {
                -ms-overflow-style: none; /* IE and Edge */
                scrollbar-width: none; /* Firefox */
            }
        }
    }

}
